import { useEffect } from 'react';
import Aos from 'aos';
import '@react-pdf-viewer/core/lib/styles/index.css';

// Importa los archivos PDF
// Importa los archivos PDF
import PagoInfo from '../assets/formularios/CamScanner_17_01_2025_10_25.pdf';
import PermisoFuncionamiento from '../assets/formularios/01h02019_resolucion_no_mineduc_cz6_2023_00654_r_cambio_de_domicilio.pdf';

const Transparencia = () => {
  useEffect(() => {
    Aos.init({ duration: 1500 });
  }, []);

  return (
    <>
      <section className="container-fluid my-5" id="costos-educativos">
        <div className="container shadow p-4">
          <div className="text-center mb-4">
            <h1 className="text-green-outline" data-aos="fade-down">Costos Educativos</h1>
          </div>
          <div className="container texto-justificado" data-aos="fade-up">
            <p>
              A continuación, se presentan los valores máximos autorizados para la matrícula y pensión según los niveles de educación.
              Esta información es transparente y está destinada a garantizar el acceso justo y adecuado para cada nivel educativo.
            </p>

            <div className="table-responsive mt-4">
              <table className="table table-bordered table-striped">
                <thead className="thead-dark text-center">
                  <tr>
                    <th>Niveles/Subniveles de Educación</th>
                    <th>Valor máximo de matrícula autorizado (US$)</th>
                    <th>Valor máximo de pensión autorizado (US$)</th>
                  </tr>
                </thead>
                <tbody className="text-center">
                  <tr>
                    <td>Inicial 1 (hasta 36 meses de edad)</td>
                    <td>86.68</td>
                    <td>138.69</td>
                  </tr>
                  <tr>
                    <td>Inicial 2</td>
                    <td>86.68</td>
                    <td>138.69</td>
                  </tr>
                  <tr>
                    <td>General Básica (1ero a 7mo)</td>
                    <td>0.00</td>
                    <td>0.00</td>
                  </tr>
                  <tr>
                    <td>Básica Superior (8vo a 10mo)</td>
                    <td>0.00</td>
                    <td>0.00</td>
                  </tr>
                  <tr>
                    <td>Bachillerato</td>
                    <td>0.00</td>
                    <td>0.00</td>
                  </tr>
                </tbody>
              </table>
            </div>

            {/* Campo para más información sobre pagos */}
            <div className="mt-5" data-aos="fade-up">
              <h3>¿Desea más información sobre los pagos?</h3>
              <p>
                Descargue el archivo con información detallada sobre los pagos y costos educativos.
              </p>
              <a href={PagoInfo} download="CamScanner_17-01-2025.pdf" className="btn btn-primary">
                Descargar información de pagos
              </a>
            </div>

            {/* Campo para permiso de funcionamiento */}
            <div className="mt-5" data-aos="fade-up">
              <h3>Nuestro Permiso de Funcionamiento</h3>
              <p>
                Puede descargar el archivo con nuestro permiso de funcionamiento vigente.
              </p>
              <a href={PermisoFuncionamiento} download="Permiso_de_Funcionamiento.pdf" className="btn btn-secondary">
                Descargar permiso de funcionamiento
              </a>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Transparencia;
