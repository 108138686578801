import React, { useEffect } from 'react';
import slogan from '../assets/slogan.webp';
import Aos from 'aos';

const Slogan = () => {

    useEffect(() => {
        Aos.init({ duration: 1500 });
    }, []);

    return (
        <div
            className='d-flex align-items-center justify-content-center parallax-container-slogan p-5 mt-5 mb-5'
            data-aos="fade-up"
        >
            <img
                src={slogan}
                alt="Slogan de Jardín Esperanza"
                className='filter-shadow img-fluid img-slogan'
                style={{ maxWidth: '100%', height: 'auto' }}
            />
        </div>
    );
};

export default Slogan;