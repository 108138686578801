import { useEffect, useState, useRef, Suspense } from 'react';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';

// Importa los componentes directamente
import Niveles from '../scanes/Niveles';
import Slogan from '../scanes/Slogan';
import DocentesHome from '../scanes/DocentesHome';
import Testimonios from '../scanes/Testimonios';
import Indicadores from '../scanes/Indicadores';
import Recomendaciones from '../scanes/Recomendaciones';

// Importa tus imágenes
import jeBanner1 from '../assets/je-banner-1.png';
import jeBanner2 from '../assets/je-banner-2.png';
import jeBanner4 from '../assets/je-banner-4.png';
import jeBanner5 from '../assets/je-banner-5.png';

const Home = () => {
  const [loading, setLoading] = useState(true); // Control de carga
  const carouselRef = useRef(null); // Referencia al carousel

  const sliderItems = [
    <img src={jeBanner1} alt="Imagen 1" className="slider-image" key="1" />,
    <img src={jeBanner2} alt="Imagen 2" className="slider-image" key="2" />,
    <img src={jeBanner4} alt="Imagen 4" className="slider-image" key="4" />,
    <img src={jeBanner5} alt="Imagen 5" className="slider-image" key="5" />,
  ];

  useEffect(() => {
    setTimeout(() => setLoading(false), 1500); // Simula la carga de contenido
  }, []);

  return (
    <>
      <div id="inicio" className="slider-container">
        {/* Slider */}
        <AliceCarousel
          ref={carouselRef}
          items={sliderItems}
          autoPlay
          autoPlayInterval={1500} // Reduce el tiempo entre imágenes a 1.5 segundos
          animationDuration={800} // Ajusta la duración de la transición a 800ms
          infinite
          disableDotsControls={false}
          disableButtonsControls={true}
        />
        <div className="slider-content position-absolute top-50 start-50 translate-middle text-center">
          <h1 className="display-2 mb-4 font-weight-bold text-white text-green-outline text-move-up-down">
            Jardín Esperanza
          </h1>
          <a href="/nosotros#inicio" className="btn btn-secondary mt-1 py-3 px-5">
            Conoce más aquí
          </a>
        </div>
      </div>

      {/* Suspense para manejar la carga diferida */}
      <Suspense
        fallback={
          <div className="loading-screen d-flex justify-content-center align-items-center">
            Cargando...
          </div>
        }
      >
        <div className={`fade-in-container ${loading ? '' : 'loaded'}`}>
          <Niveles />
          <Slogan />
          <DocentesHome />
          <Testimonios />
          <Indicadores />
          <Recomendaciones />
        </div>
      </Suspense>
    </>
  );
};

export default Home;
