import React, { useEffect, useState, Suspense } from 'react';
import BannerAnimado from '../Components/BannerAnimado';
import CardActividad from 'Components/CardActividad';
import natacion from '../assets/niveles/natacion-1.webp';
import equitacion from '../assets/galeria/14.webp';
import futbol from '../assets/galeria/futbol-2.webp';
import terapiaLenguaje from '../assets/galeria/lenguaje-1.webp';
import ingles from '../assets/galeria/10.webp';
import musica from '../assets/galeria/niño-tocando-tambor.webp';
import kungfu from '../assets/galeria/AD_Kungfu.jpeg';
import serviciosAdicionales from '../assets/titulos/servicios-adicionales.webp';
import textos from '../Data/textos.json';


const Services = () => {
  const [, setLoading] = useState(true);

  const scrollToSection = (inicio) => {
    window.location.hash = "#" + inicio;
  };

  useEffect(() => {
    scrollToSection("inicio");
    setTimeout(() => setLoading(false), 500); // Simulando carga de contenido
  }, []);

  return (
    <>
      <BannerAnimado
        tittle="Nuestros Servicios"
        size="display-4"
      />
      
      <Suspense fallback={<div className="loading-screen d-flex justify-content-center align-items-center">Cargando...</div>}>
          <div className="container-fluid pt-5">
            <div className="container pb-3">
              <div className="text-center pb-2">
                <img src={serviciosAdicionales} className="titulos" alt="Servicios adicionales" />
              </div>
              <div className="row">
              <CardActividad
                  titulo={textos.serviciosAdicionales.servicioCuatro.nombre}
                  descripcion={textos.serviciosAdicionales.servicioCuatro.descripcion}
                  imagen={ingles}
                />
                <CardActividad
                  titulo={textos.serviciosAdicionales.servicioUno.nombre}
                  descripcion={textos.serviciosAdicionales.servicioUno.descripcion}
                  imagen={natacion}
                />
                <CardActividad
                  titulo={textos.serviciosAdicionales.servicioDos.nombre}
                  descripcion={textos.serviciosAdicionales.servicioDos.descripcion}
                  imagen={terapiaLenguaje}
                />
                <CardActividad
                  titulo={textos.serviciosAdicionales.servicioTres.nombre}
                  descripcion={textos.serviciosAdicionales.servicioTres.descripcion}
                  imagen={equitacion}
                />
                <CardActividad
                  titulo={textos.serviciosAdicionales.servicioCinco.nombre}
                  descripcion={textos.serviciosAdicionales.servicioCinco.descripcion}
                  imagen={futbol}
                />
                <CardActividad
                  titulo={textos.serviciosAdicionales.servicioSeis.nombre}
                  descripcion={textos.serviciosAdicionales.servicioSeis.descripcion}
                  imagen={musica}
                />
                 <CardActividad
                  titulo={textos.serviciosAdicionales.servicioSiete.nombre}
                  descripcion={textos.serviciosAdicionales.servicioSiete.descripcion}
                  imagen={kungfu}
                />
              </div>
            </div>
          </div>
      </Suspense>
    </>
  );
};

export default Services;
