import { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';
import Aos from 'aos';

const GaleriaVideos = () => {
    const [videos, setVideos] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [selectedPlaylist, setSelectedPlaylist] = useState('PLzRJSDvB5xi2KUpMPH_T9Epxuxs5mIeet'); // Lista predeterminada (Testimonios)
    const apiKey = 'AIzaSyDqD0EwUfgagn97cl3lErRrHz8Aa3A-guE'; // Reemplaza con tu clave API de YouTube

    // Listas de reproducción disponibles
    const playlists = [
        { id: 'PLzRJSDvB5xi2KUpMPH_T9Epxuxs5mIeet', name: 'Testimonios' },
        { id: 'PLzRJSDvB5xi1oo7ePEitMmMma0GVm8r__', name: 'Actividades' },
    ];

    // Cargar los videos de la lista seleccionada
    useEffect(() => {
        Aos.init({ duration: 1500 });

        const fetchVideos = async () => {
            try {
                setLoading(true);
                const response = await fetch(
                    `https://www.googleapis.com/youtube/v3/playlistItems?part=snippet&maxResults=10&playlistId=${selectedPlaylist}&key=${apiKey}`
                );
                const data = await response.json();
                if (data.items && data.items.length > 0) {
                    setVideos(data.items);
                    setError(null); // Sin error si se obtienen videos
                } else {
                    setVideos([]);
                    setError('No se encontraron videos en esta lista');
                }
            } catch (error) {
                console.error('Error fetching videos:', error);
                setError('Hubo un error al cargar los videos');
            } finally {
                setLoading(false);
            }
        };

        fetchVideos();
    }, [selectedPlaylist]);

    if (loading) {
        return (
            <div className="text-center py-5">
                <div className="spinner-border" role="status">
                    <span className="visually-hidden">Cargando...</span>
                </div>
            </div>
        );
    }

    return (
        <>
            <div className="text-center galeria-back">
                <h2>Galería de Videos</h2>
            </div>

            {/* Botones para seleccionar la lista de reproducción */}
            <div className="text-center mt-4">
                {playlists.map((playlist) => (
                    <button
                        key={playlist.id}
                        className={`btn mx-2 ${selectedPlaylist === playlist.id ? 'btn-primary' : 'btn-outline-primary'}`}
                        onClick={() => setSelectedPlaylist(playlist.id)}
                    >
                        {playlist.name}
                    </button>
                ))}
            </div>

            {/* Mostrar error si no hay videos */}
            {error && (
                <div className="text-center text-danger mt-3">
                    <h4>{error}</h4>
                </div>
            )}

            <div className="container-fluid galeria-video py-5">
                <div className="container-md">
                    <Swiper
                        modules={[Navigation, Pagination, Autoplay]}
                        navigation
                        loop={true}
                        spaceBetween={30}
                        breakpoints={{
                            320: {
                                slidesPerView: 1, // Pantallas pequeñas (móviles)
                                spaceBetween: 10,
                            },
                            768: {
                                slidesPerView: 2, // Pantallas medianas (tablets)
                                spaceBetween: 20,
                            },
                            1024: {
                                slidesPerView: 3, // Pantallas grandes (laptops y más)
                                spaceBetween: 30,
                            },
                        }}
                    >
                        {videos.map((video) => (
                            <SwiperSlide key={video.snippet.resourceId.videoId}>
                                <iframe
                                    src={`https://www.youtube.com/embed/${video.snippet.resourceId.videoId}?modestbranding=1&rel=0&autohide=1`}
                                    title={video.snippet.title}
                                    className="video-container borde color-borde-video img-fluid"
                                    frameBorder="0"
                                    allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen
                                    data-aos="fade-up"
                                ></iframe>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>
            </div>
        </>
    );
};

export default GaleriaVideos;
