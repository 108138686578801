import React, { useEffect, useState } from 'react';
import { gapi } from 'gapi-script';

const NovedadesBlog = () => {
  const [posts, setPosts] = useState([]);
  const [error, setError] = useState(null);

  const apiKey = 'AIzaSyAuZJq9_TzJuYK_rC2E14JYtLUdlnlS2ak'; // Reemplaza con tu clave de API
  const blogId = '6665091418400964060'; // Reemplaza con el ID de tu blog público

  useEffect(() => {
    const initializeGapiClient = () => {
      gapi.load('client', () => {
        gapi.client
          .init({
            apiKey, // Usamos la API Key
            discoveryDocs: ['https://www.googleapis.com/discovery/v1/apis/blogger/v3/rest'],
          })
          .then(() => {
            console.log('Blogger API client initialized');
            fetchPosts();
          })
          .catch((err) => {
            console.error('Error initializing Google API client:', err);
            setError('Error al inicializar la API de Blogger.');
          });
      });
    };

    const fetchPosts = () => {
      gapi.client.blogger.posts
        .list({
          blogId,
          maxResults: 10, // Número máximo de publicaciones
        })
        .then((response) => {
          console.log('Posts fetched successfully:', response);
          setPosts(response.result.items || []);
        })
        .catch((err) => {
          console.error('Error fetching posts:', err);
          setError('Error al obtener publicaciones del blog.');
        });
    };

    initializeGapiClient();
  }, []);

  return (
    <div
      style={{
        padding: '20px',
        backgroundColor: '#D8E9D8', // Color detrás del contenedor
        minHeight: '100vh', // Asegura que el fondo cubra toda la ventana
      }}
    >
      <h2>Últimas Novedades</h2>
      {/* Mensaje con el enlace al blog completo */}
      <p>
        Si desea ver nuestros blogs completos, puede acceder a{' '}
        <a
          href="https://jardin-esperanza.blogspot.com/"
          target="_blank"
          rel="noopener noreferrer"
          style={{
            color: '#007BFF',
            textDecoration: 'none',
            fontWeight: 'bold',
          }}
        >
          nuestro blog oficial
        </a>.
      </p>

      {error && <p style={{ color: 'red' }}>{error}</p>}
      {posts.length === 0 && !error && <p>Cargando publicaciones...</p>}
      <div>
        {posts.map((post) => (
          <div
            key={post.id}
            style={{
              marginBottom: '20px',
              border: '1px solid #ddd',
              borderRadius: '8px',
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
              padding: '20px',
              backgroundColor: '#fff', // El contenedor sigue blanco
            }}
          >
            {/* Título del blog */}
            <h3 style={{ marginBottom: '10px', color: '#333' }}>{post.title}</h3>

            {/* Fecha de publicación */}
            <p style={{ fontSize: '14px', color: '#666', marginBottom: '15px' }}>
              Publicado el {new Date(post.published).toLocaleDateString()}
            </p>

            {/* Contenido completo del blog */}
            <div
              dangerouslySetInnerHTML={{
                __html: post.content, // Renderizamos el contenido completo del blog
              }}
              style={{ textAlign: 'justify', lineHeight: '1.8', color: '#444' }}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default NovedadesBlog;
