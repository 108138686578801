import React, { useEffect, useState } from 'react';
import Aos from 'aos';
import 'aos/dist/aos.css';
import BannerAnimado from '../Components/BannerAnimado';
import foto1 from '../assets/galeria/001.png';
import foto2 from '../assets/galeria/002.png';
import foto3 from '../assets/galeria/003.png';
import foto4 from '../assets/galeria/004.png';
import foto5 from '../assets/galeria/005.png';
import foto7 from '../assets/galeria/007.png';
import foto8 from '../assets/galeria/008.png';
import foto9 from '../assets/galeria/009.png';
import foto10 from '../assets/galeria/010.png';
import foto11 from '../assets/galeria/011.png';
import foto12 from '../assets/galeria/012.png';
import foto14 from '../assets/galeria/014.png';
import foto15 from '../assets/galeria/015.png';
import foto19 from '../assets/galeria/019.png';
import foto20 from '../assets/galeria/020.png';
import foto21 from '../assets/galeria/021.png';
import foto26 from '../assets/galeria/026.png';
import foto27 from '../assets/galeria/027.png';

const Gallery = () => {
  const [galeria, setGaleria] = useState('academicas');
  const [selectedImage, setSelectedImage] = useState(null);

  const scrollToSection = (inicio) => {
    window.location.hash = "#" + inicio;
  };

  useEffect(() => {
    Aos.init({
      duration: 700,
      easing: 'ease-in-out',
      once: false,
      offset: 50,
    });
    scrollToSection("inicio");
  }, []);

  const openImage = (img) => {
    setSelectedImage(img);
  };

  const closeImage = () => {
    setSelectedImage(null);
  };

  return (
    <>
      <BannerAnimado tittle="Galería" />

      <div className="container-fluid pt-5 pb-3">
        <div className="container">
          <div className="row">
            <div className="col-12 text-center mb-2">
              <ul className="list-inline mb-4">
                <li
                  className={`btn btn-outline-primary m-1 ${galeria === 'academicas' ? 'active' : ''}`}
                  onClick={() => setGaleria('academicas')}
                >
                  Académicas
                </li>
                <li
                  className={`btn btn-outline-primary m-1 ${galeria === 'socioCulturales' ? 'active' : ''}`}
                  onClick={() => setGaleria('socioCulturales')}
                >
                  Socio culturales
                </li>
                <li
                  className={`btn btn-outline-primary m-1 ${galeria === 'extraCurriculares' ? 'active' : ''}`}
                  onClick={() => setGaleria('extraCurriculares')}
                >
                  Extra curriculares
                </li>
              </ul>
            </div>
          </div>

          <div className="row">
            {galeria === 'academicas' ? (
              <>
                {[foto7, foto8, foto9, foto10, foto19, foto20, foto21].map((foto, index) => (
                  <div
                    className="col-md-4 mb-4"
                    key={index}
                    onClick={() => openImage(foto)}
                    data-aos="fade-up"
                  >
                    <div className="gallery-image-container">
                      <img src={foto} alt={`Academica ${index}`} className="img-fluid gallery-image" />
                    </div>
                  </div>
                ))}
              </>
            ) : galeria === 'socioCulturales' ? (
              <>
                {[foto1, foto2, foto3, foto4, foto5, foto26, foto27].map((foto, index) => (
                  <div
                    className="col-md-4 mb-4"
                    key={index}
                    onClick={() => openImage(foto)}
                    data-aos="fade-up"
                  >
                    <div className="gallery-image-container">
                      <img src={foto} alt={`SocioCultural ${index}`} className="img-fluid gallery-image" />
                    </div>
                  </div>
                ))}
              </>
            ) : galeria === 'extraCurriculares' ? (
              <>
                {[foto11, foto12, foto14, foto15].map((foto, index) => (
                  <div
                    className="col-md-4 mb-4"
                    key={index}
                    onClick={() => openImage(foto)}
                    data-aos="fade-up"
                  >
                    <div className="gallery-image-container">
                      <img src={foto} alt={`ExtraCurricular ${index}`} className="img-fluid gallery-image" />
                    </div>
                  </div>
                ))}
              </>
            ) : null}
          </div>
        </div>
      </div>

      {/* Modal para mostrar la imagen seleccionada */}
      {selectedImage && (
        <div className="image-modal" onClick={closeImage}>
          <div className="modal-content">
            <img src={selectedImage} alt="Selected" className="img-fluid" />
          </div>
        </div>
      )}

      <style jsx>{`
        .gallery-image-container {
          position: relative;
          width: 100%;
          padding-top: 75%; /* Aspect ratio 4:3 */
          overflow: hidden;
          background: #f8f8f8;
          border-radius: 5px;
          box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
        }

        .gallery-image {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          object-fit: cover; /* Asegura que las imágenes llenen el contenedor sin deformarse */
        }

        .image-modal {
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: rgba(0, 0, 0, 0.8);
          display: flex;
          justify-content: center;
          align-items: center;
          z-index: 9999;
        }

        .modal-content {
          max-width: 90%;
          max-height: 90%;
        }

        .modal-content img {
          width: 100%;
          height: auto;
          object-fit: contain;
        }
      `}</style>
    </>
  );
};

export default Gallery;
