import AliceCarousel from 'react-alice-carousel';
import React, { useEffect } from 'react';
import CardDocentes from '../Components/CardDocentes';
import textos from '../Data/textos.json';
import tituloProfes from '../assets/titulos/profes.webp';
import karleymedina from '../assets/docentes/karley-medina.png';
import gabyorellana from '../assets/docentes/gaby-orellana.png';
import kathyanaChoco from '../assets/docentes/kathyana-choco.png';
import kristyCuzco from '../assets/docentes/kristy-cuzco.png';
import lucyVacacela from '../assets/docentes/lucy-vacacela.png';
import micaAvila from '../assets/docentes/mica-avila.png';
import Aos from 'aos';

const Docentes = () => {
    useEffect(() => {
        Aos.init({ duration: 1500 });
        window.scrollTo(0, 0);
    }, []);

    const itemsDocentes = [
        <CardDocentes
        img={micaAvila}
        direccion="derecha"
        nombres={textos.docentes.docente2.nombre}
        profesion={textos.docentes.docente2.profesion}
        descripcion={textos.docentes.docente2.descripcion}
    />,
        <CardDocentes
            img={karleymedina}
            direccion="derecha"
            nombres={textos.docentes.docente6.nombre}
            profesion={textos.docentes.docente6.profesion}
            descripcion={textos.docentes.docente6.descripcion}
        />,
        <CardDocentes
            img={gabyorellana}
            direccion="izquierda"
            nombres={textos.docentes.docente4.nombre}
            profesion={textos.docentes.docente4.profesion}
            descripcion={textos.docentes.docente4.descripcion}
        />,
        <CardDocentes
            img={kathyanaChoco}
            direccion="derecha"
            nombres={textos.docentes.docente3.nombre}
            profesion={textos.docentes.docente3.profesion}
            descripcion={textos.docentes.docente3.descripcion}
        />,
        <CardDocentes
            img={kristyCuzco}
            direccion="izquierda"
            nombres={textos.docentes.docente1.nombre}
            profesion={textos.docentes.docente1.profesion}
            descripcion={textos.docentes.docente1.descripcion}
        />,
        <CardDocentes
            img={lucyVacacela}
            direccion="derecha"
            nombres={textos.docentes.docente5.nombre}
            profesion={textos.docentes.docente5.profesion}
            descripcion={textos.docentes.docente5.descripcion}
        />,
    ];

    const responsive = {
        0: { items: 1 },
        768: { items: 1 },
        1024: { items: 1 }
    };

    return (
        <div className="container-fluid pt-5 pb-3" id="docentes">
            <div className="container">
                <div className="text-center pb-2">
                    <img src={tituloProfes} className="titulos" alt="Nuestras profes" data-aos="fade-down" />
                </div>
                <AliceCarousel
                    items={itemsDocentes}
                    responsive={responsive}
                    autoPlay
                    autoPlayInterval={3000}
                    animationDuration={1000}
                    infinite
                    mouseTracking
                    touchTracking
                    disableDotsControls={false} // Los puntos de navegación siguen visibles
                    disableButtonsControls={true} // Oculta los botones de navegación (flechas)
                />

            </div>
        </div>
    );
}

export default Docentes;
