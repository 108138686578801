import { useEffect } from 'react';
import Aos from 'aos';
import '@react-pdf-viewer/core/lib/styles/index.css';
// Importa los archivos PDF
import FormularioReclamacion from '../assets/formularios/FORMULARIODERECLAMACIÓNCHUBBE.pdf';
import ProcedimientoReclamos from '../assets/formularios/PROCEDIMIENTOPARARECLAMOS.pdf';

const SeguroEstudiantil = () => {
    useEffect(() => {
        Aos.init({ duration: 1500 });
    }, []);

    return (
        <>
            <section className="container-fluid my-5" id="seguro-estudiantil">
                <div className="container shadow p-4">
                    <div className="text-center mb-4">
                        <h1 className="text-green-outline" data-aos="fade-down">Seguro Estudiantil</h1>
                    </div>
                    <div className="container texto-justificado" data-aos="fade-up">
                        <p>
                            Nuestros estudiantes cuentan con un seguro que los protege las 24 horas del día, los 365 días del año, en cualquier lugar del mundo.
                            Este respaldo garantiza tranquilidad y seguridad en todo momento, cubriendo una amplia gama de eventualidades para su bienestar y el de sus familias.
                        </p>
                        <h5 className="mt-4 text-green-outline">Coberturas, límites y montos asegurados por persona:</h5>
                        <ul>
                            <li><strong>Muerte accidental:</strong> $10,000</li>
                            <li><strong>Incapacidad total y permanente por accidente:</strong> Hasta $10,000</li>
                            <li><strong>Desmembración accidental:</strong> Hasta $10,000</li>
                            <li><strong>Beca estudiantil (por muerte accidental del representante legal):</strong> $5,000</li>
                            <li><strong>Gastos médicos por accidente:</strong> Hasta $1,000</li>
                            <li><strong>Gastos de sepelio por accidente:</strong> Hasta $500</li>
                            <li><strong>Gastos de ambulancia por accidente:</strong> Hasta $250</li>
                            <li><strong>Renta diaria por hospitalización (por accidente):</strong> $30 por día (máximo 30 días, hasta $900)</li>
                            <li><strong>Asistencia de emergencia accidental:</strong> Incluida</li>
                        </ul>
                        <h5 className="mt-4 text-green-outline">Costo anual por persona:</h5>
                        <p><strong>Prima neta anual:</strong> $16.67</p>
                        <h5 className="mt-4 text-green-outline">Deducibles:</h5>
                        <ul>
                            <li><strong>Gastos médicos por accidente:</strong> $20 por persona y por evento.</li>
                            <li><strong>Renta diaria hospitalaria:</strong> 1 día por persona y por evento.</li>
                        </ul>
                    </div>
                </div>
            </section>

            {/* Secciones horizontales */}
            <section className="container-fluid my-5">
                <div className="container d-flex justify-content-between align-items-start flex-wrap">
                    {/* Formulario de Reclamación */}
                    <div className="shadow p-4 m-2 flex-grow-1">
                        <div className="text-center mb-4">
                            <h2 className="text-green-outline" data-aos="fade-down">Formulario de Reclamación</h2>
                        </div>
                        <div className="text-center mt-3">
                            <a
                                href={FormularioReclamacion}
                                download="FORMULARIODERECLAMACIÓNCHUBBE.pdf"
                                className="btn btn-secondary mt-1 py-3 px-5"
                                data-aos="fade-up"
                            >
                                Descargar Formulario
                            </a>
                        </div>
                    </div>

                    {/* Procedimiento para Reclamos */}
                    <div className="shadow p-4 m-2 flex-grow-1">
                        <div className="text-center mb-4">
                            <h2 className="text-green-outline" data-aos="fade-down">Procedimiento para Reclamos</h2>
                        </div>
                        <div className="text-center mt-3">
                            <a
                                href={ProcedimientoReclamos}
                                download="PROCEDIMIENTOPARARECLAMOS.pdf"
                                className="btn btn-secondary mt-1 py-3 px-5"
                                data-aos="fade-up"
                            >
                                Descargar Procedimiento
                            </a>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default SeguroEstudiantil;
