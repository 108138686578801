import { useState } from 'react';
import emailjs from 'emailjs-com';

const AdmissionForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    message: '',
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;

    // Validar que solo se permitan números en el campo de teléfono
    if (name === 'phone' && !/^\d*$/.test(value)) {
      return; // Ignora valores no numéricos
    }

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    emailjs
      .send(
        'service_ql40tau', // ID del servicio
        'template_c84gt1b', // ID del template
        formData, // Datos del formulario
        'NnsRjoticIxRx4gMX' // Public Key (User ID)
      )
      .then(
        (response) => {
          console.log('Correo enviado con éxito:', response);
          alert('¡Tu solicitud ha sido enviada con éxito!');
          setFormData({
            name: '',
            email: '',
            phone: '',
            message: '',
          });
        },
        (error) => {
          console.error('Error al enviar el correo:', error);
          alert('Hubo un error al enviar tu solicitud. Intenta nuevamente.');
        }
      )
      .finally(() => setIsSubmitting(false));
  };

  return (
    <section className="container py-5" style={{ backgroundColor: '#f9f9f9' }}>
      <div className="row justify-content-center">
        <div className="col-lg-8 col-md-10">
          <div className="card border-0 rounded-lg shadow-lg">
            <div className="card-body p-4">
              <div className="text-center mb-4">
                <h2 className="h4 text-dark mb-2">Formulario de Admisiones</h2>
                <p className="text-muted">
                  Completa el siguiente formulario para enviar tu solicitud.
                </p>
              </div>
              <form onSubmit={handleSubmit}>
                <div className="form-group mb-3">
                  <label htmlFor="name">Nombre</label>
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="form-group mb-3">
                  <label htmlFor="email">Correo electrónico</label>
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="form-group mb-3">
                  <label htmlFor="phone">Teléfono</label>
                  <input
                    type="text"
                    className="form-control"
                    id="phone"
                    name="phone"
                    value={formData.phone}
                    onChange={handleChange}
                    inputMode="numeric"
                    required
                  />
                </div>
                <div className="form-group mb-3">
                  <label htmlFor="message">Mensaje</label>
                  <textarea
                    className="form-control"
                    id="message"
                    name="message"
                    rows="4"
                    value={formData.message}
                    onChange={handleChange}
                    required
                  ></textarea>
                </div>
                <button
                  type="submit"
                  className="btn btn-primary btn-block"
                  disabled={isSubmitting}
                >
                  {isSubmitting ? 'Enviando...' : 'Enviar Solicitud'}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AdmissionForm;
