import React, { useEffect, useState, Suspense } from 'react';
import BannerAnimado from '../Components/BannerAnimado';
import CardNivelDetalle from 'Components/CardNivelDetalle';
import salaCuna from '../assets/niveles/sala-cuna-servicios.webp';
import maternal1 from '../assets/niveles/maternal1-servicios.webp';
import maternal2 from '../assets/niveles/maternal2-servicios.webp';
import inicial1 from '../assets/niveles/inicial-1-servicios.webp';
import inicial2 from '../assets/niveles/inicial-2-servicios.webp';
import tituloSalaCuna from '../assets/titulos/salacuna.webp';
import tituloMaternal1 from '../assets/titulos/maternal1.webp';
import tituloMaternal2 from '../assets/titulos/maternal2.webp';
import tituloInicial1 from '../assets/titulos/inicial1.webp';
import tituloInicial2 from '../assets/titulos/inicial2.webp';
import textos from '../Data/textos.json';

const Services = () => {
    const [loading, setLoading] = useState(true);

    const scrollToSection = (inicio) => {
        window.location.hash = "#" + inicio;
    };

    useEffect(() => {
        scrollToSection("inicio");
        setTimeout(() => setLoading(false), 500); // Simulando carga de contenido
    }, []);

    return (
        <>
            <BannerAnimado
                tittle="Oferta Académica"
                size="display-4"
            />

            <Suspense fallback={<div className="loading-screen d-flex justify-content-center align-items-center">Cargando...</div>}>
                <div className={`fade-in-container ${loading ? "" : "loaded"}`}>
                    <div className="container-fluid" id="niveles">
                        <div className="container">
                            <CardNivelDetalle
                                imgTitulo={tituloSalaCuna}
                                descripcion={textos.niveles.salaCuna.descripcionCompleta}
                                edad={textos.niveles.salaCuna.edad}
                                horario={textos.niveles.salaCuna.horario}
                                direccionImg="derecha"
                                img={salaCuna}
                            />
                            <CardNivelDetalle
                                imgTitulo={tituloMaternal1}
                                descripcion={textos.niveles.maternal.uno.descripcionCompleta}
                                edad={textos.niveles.maternal.uno.edad}
                                horario={textos.niveles.maternal.uno.horario}
                                direccionImg="izquierda"
                                img={maternal1}
                            />
                            <CardNivelDetalle
                                imgTitulo={tituloMaternal2}
                                descripcion={textos.niveles.maternal.dos.descripcionCompleta}
                                edad={textos.niveles.maternal.dos.edad}
                                horario={textos.niveles.maternal.dos.horario}
                                direccionImg="derecha"
                                img={maternal2}
                            />
                            <CardNivelDetalle
                                imgTitulo={tituloInicial1}
                                descripcion={textos.niveles.inicial.uno.descripcionCompleta}
                                edad={textos.niveles.inicial.uno.edad}
                                horario={textos.niveles.inicial.uno.horario}
                                direccionImg="izquierda"
                                img={inicial1}
                            />
                            <CardNivelDetalle
                                imgTitulo={tituloInicial2}
                                descripcion={textos.niveles.inicial.dos.descripcionCompleta}
                                edad={textos.niveles.inicial.dos.edad}
                                horario={textos.niveles.inicial.dos.horario}
                                direccionImg="derecha"
                                img={inicial2}
                            />
                        </div>
                    </div>

                </div>
            </Suspense>
        </>
    );
};

export default Services;
