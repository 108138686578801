import { useState } from 'react';

const LocationPage = () => {
  const [view, setView] = useState('map'); // Estado para manejar la vista seleccionada

  return (
    <section className="container py-5" style={{ backgroundColor: '#f9f9f9' }}>
      <div className="row justify-content-center">
        <div className="col-lg-8 col-md-10">
          <div className="card border-0 rounded-lg shadow-lg">
            <div className="card-body p-4">
              <div className="text-center mb-3">
                {/* Título más pequeño */}
                <h2 className="h4 text-dark mb-2">
                  Dirección del Establecimiento
                </h2>
                <p className="text-muted mb-4">
                  Dirección: 1234 Calle Ejemplo, Ciudad, País
                </p>
              </div>

              {/* Botones para seleccionar la vista */}
              <div className="d-flex justify-content-center mb-3">
                <button
                  className={`btn ${view === 'map' ? 'btn-primary' : 'btn-outline-primary'} mx-2 py-2 px-4`}
                  onClick={() => setView('map')}
                  style={{
                    borderRadius: '30px',
                    transition: 'all 0.3s ease',
                    border: '2px solid #007bff',
                  }}
                >
                  Mapa
                </button>
                <button
                  className={`btn ${view === 'streetview' ? 'btn-primary' : 'btn-outline-primary'} mx-2 py-2 px-4`}
                  onClick={() => setView('streetview')}
                  style={{
                    borderRadius: '30px',
                    transition: 'all 0.3s ease',
                    border: '2px solid #007bff',
                  }}
                >
                  Street View
                </button>
              </div>

              {/* Contenedor con la vista seleccionada */}
              <div
                className="map-container"
                style={{
                  position: 'relative',
                  height: '400px',
                  width: '100%',
                  borderRadius: '15px',
                  overflow: 'hidden',
                  border: '2px solid #ddd',
                  boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
                  transition: 'all 0.3s ease',
                }}
              >
                {view === 'map' && (
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3813.524612017788!2d-79.0303825225734!3d-2.889964031944665!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x91cd191969acee41%3A0x591be6924dc4933a!2sJardin%20Esperanza!5e1!3m2!1sen!2sec!4v1735048702616!5m2!1sen!2sec"
                    width="100%"
                    height="100%"
                    style={{
                      border: 0,
                      borderRadius: '15px',
                    }}
                    title="Mapa de la ubicación"
                    allowFullScreen=""
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                  />
                )}

                {view === 'streetview' && (
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!4v1735048863971!6m8!1m7!1sr2Ha7FJCXFVbA4P9w5Sl0A!2m2!1d-2.88979721914842!2d-79.02535229662845!3f173.09137530247418!4f-20.87687852932679!5f0.7820865974627469"
                    width="100%"
                    height="100%"
                    style={{
                      border: 0,
                      borderRadius: '15px',
                    }}
                    title="Vista de Street View"
                    allowFullScreen=""
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default LocationPage;
