import { useEffect } from 'react';
import BannerAnimado from '../Components/BannerAnimado';
import Docentes from '../scanes/Docentes';
import PropuestaValor from '../scanes/PropuestaValor';
import Valores from '../scanes/Valores';
/* import tituloValores from '../assets/titulos/nuestros-valores.webp'; */
import Aos from 'aos';

const About = () => {


  const scrollToSection = (inicio) => {
    window.location.hash = "#" + inicio;
  };

  useEffect(() => {
    Aos.init({ duration: 1500 });
    scrollToSection("inicio")
  }, []);


  return (
    <>
      <BannerAnimado
        tittle="Sobre Nosotros"
      />
      <PropuestaValor />

      <section className='container-fluid mb-5'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-6 col-md-6'>
              <div className='container-mision shadow m-2' data-aos="fade-right">
                <div className="text-center pt-5 mb-3" >
                  {/* <img src={tituloValores} className='titulos-fil' alt="Nuestras profes" data-aos="fade-right"/> */}
                  <h1 className='mt-3 text-green-outline' data-aos="fade-down">Misión</h1>
                </div>
                <div className='container texto-justificado' data-aos="fade-up">
                  Somos una Cooperativa de Servicios Educacionales comprometida con ofrecer una formación inclusiva y de calidad,
                  caracterizada por la calidez y basada en valores y principios cooperativos. Nuestro enfoque está dirigido a niñas, niños y sus familias,
                  con la convicción de que podemos potenciar su desarrollo integral y contribuir a la construcción de una sociedad más justa y equitativa.
                </div>
              </div>
            </div>
            <div className='col-lg-6 col-md-6'>
              <div className='container-vision shadow m-2' data-aos="fade-down-right">
                <div className="text-center pt-5 mb-3" >
                  {/*   <img src={tituloValores} className='titulos-fil' alt="Nuestras profes" data-aos="fade-right"/> */}
                  <h1 className='mt-3 text-green-outline' data-aos="fade-down">Visión</h1>
                </div>
                <div className='container texto-justificado' data-aos="fade-up">
                  Ser un centro de referencia en calidad educativa dentro del sistema educativo ecuatoriano,
                  destacándonos por nuestra identidad cooperativista. Aspiramos a liderar la formación integral
                  de los niños y niñas mediante actividades pedagógicas innovadoras, utilizando metodologías y
                  tecnologías modernas en ambientes funcionales y agradables. Nuestro objetivo es ofrecer a los
                  niños y niñas un entorno que les brinde alegría, seguridad y afecto, garantizando su desarrollo
                  integral y comprometiéndonos con el bienestar y crecimiento de nuestra comunidad educativa.
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>


      <Valores />
      <Docentes />
    </>
  )
}

export default About