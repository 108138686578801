import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import jeLogo from '../assets/logos/je-logo-full-color.webp';
import Aos from 'aos';

const Header = () => {
    const location = useLocation();

    useEffect(() => {
        Aos.init({ duration: 1500 });
    }, []);

    return (
        <nav className="container-fluid shadow nav-border-top bg-light">
            <div className="navbar navbar-expand-lg bg-light navbar-light py-3 py-lg-0 px-0 px-lg-5">
                <div className="toger">
                    <Link to="/" className="container-logo p-1">
                        <img src={jeLogo} alt="Jardín Esperanza" className="img-fluid logo-width" />
                    </Link>
                    <button
                        className="navbar-toggler"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#navbarNav"
                        aria-controls="navbarNav"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                    >
                        <span className="navbar-toggler-icon"></span>
                    </button>
                </div>
                <div className="collapse navbar-collapse" id="navbarNav">
                    <div className="navbar-nav mx-0 py-1">
                        <Link
                            to="/"
                            className={location.pathname === '/' ? 'nav-item nav-link active' : 'nav-item nav-link'}
                        >
                            Inicio
                        </Link>
                        <Link
                            to="/nosotros"
                            className={location.pathname === '/nosotros' ? 'nav-item nav-link active' : 'nav-item nav-link'}
                        >
                            Conócenos
                        </Link>
                        <div className="nav-item dropdown">
                            <button
                                className="nav-link dropdown-toggle"
                                id="dropdownMenuButton"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                            >
                                Nuestros Servicios
                            </button>
                            <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuButton">
                                <li>
                                    <Link
                                        to="/oferta"
                                        className="dropdown-item"
                                    >
                                        Oferta Académica
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        to="/adicionales"
                                        className="dropdown-item"
                                    >
                                        Servicios Adicionales
                                    </Link>
                                </li>
                            </ul>
                        </div>
                        <Link
                            to="/Encuentranos"
                            className={location.pathname === '/Encuentranos' ? 'nav-item nav-link active' : 'nav-item nav-link'}
                        >
                            Encuéntranos
                        </Link>
                        <Link
                            to="/galeria"
                            className={location.pathname === '/galeria' ? 'nav-item nav-link active' : 'nav-item nav-link'}
                        >
                            Galería de Recuerdos
                        </Link>
                        <div className="nav-item dropdown">
                            <button
                                className="nav-link dropdown-toggle"
                                id="dropdownMenuButton"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                            >
                                Más
                            </button>
                            <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuButton">
                                <li>
                                    <Link
                                        to="/transparencia"
                                        className="dropdown-item"
                                    >
                                        Transparencia
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        to="/novedades"
                                        className="dropdown-item"
                                    >
                                        Novedades
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        to="/galeria_videos"
                                        className="dropdown-item"
                                    >
                                        Multimedia
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        to="/seguro"
                                        className="dropdown-item"
                                    >
                                        Seguro
                                    </Link>
                                </li>
                            </ul>
                        </div>

                        <div className="container-bt-contactanos">
                            <Link
                                to="/admisiones"
                                className="btn color-changing-button px-4"
                            >
                                Admisiones
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    );
};

export default Header;
