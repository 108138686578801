import React, { useEffect } from 'react';
import CardNivel from '../Components/CardNivel';
import textos from '../Data/textos.json';
import tituloNiveles from '../assets/titulos/niveles.webp';
import jeinicial from '../assets/niveles/je-inicial-poratada.webp';
import jematernal from '../assets/niveles/je-maternal-poratada.webp';
import jesalacuna from '../assets/niveles/je-sala-cuna-poratada.webp';
import Aos from 'aos';

const Niveles = () => {

    useEffect(() => {
        Aos.init({ duration: 1500 });
    }, []);

    return (
        <div className="border-0 container-fluid pt-5">
            <div className="container">
                <div className="text-center pb-2">
                    <img src={tituloNiveles} className='titulos' alt="niveles" data-aos="fade-up" />
                </div>

                <div className="row mx-2">
                    <CardNivel
                        img={jesalacuna}
                        tittle="Sala Cuna"
                        description={textos.niveles.salaCuna.descripcionCorta}
                        edad="3 - 12 meses"
                        horario="Jornada Completa"
                        data-aos="fade-up"
                    />
                    <CardNivel
                        img={jematernal}
                        tittle="Maternal"
                        description={textos.niveles.maternal.descripcionCorta}
                        edad="1 - 2 Años"
                        horario="Jornada Completa"
                        data-aos="fade-up"
                    />
                    <CardNivel
                        img={jeinicial}
                        tittle="Inicial"
                        description={textos.niveles.inicial.descripcionCorta}
                        edad="3 - 4 Años"
                        horario="Jornada Completa"
                        data-aos="fade-up"
                    />
                </div>
            </div>
        </div>
    );
};

export default Niveles;
