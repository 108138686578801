import { Navigate, Route, Routes } from "react-router-dom";
import Header from "./Components/Header";
import Footer from "./Components/Footer";
import WhatsApp from "./Components/WhatsApp";

// Páginas Principales
import Home from "./Pages/Home";
import About from "./Pages/About";
import Services from "./Pages/Services";
import Gallery from "./Pages/Gallery";
import Contact from "./Pages/Contact";
import Adicionales from "./Pages/Adicionales";
import OfertaAcademica from "./Pages/OfertaAcademica";
import GaleriaVideos from "./Pages/GaleriaVideos";
import Encuentranos from "./Pages/Encuentranos";
import Admisiones from "./Pages/Admisiones";
import Transparencia from "./Pages/Transparencia";
import NovedadesBlog from "./Pages/NovedadesBlog";
import Seguro from "./Pages/Seguro";

// Blogs
import BlogUno from "./Pages/Blogs/BlogUno";
import BlogDos from "./Pages/Blogs/BlogDos";
import BlogTres from "./Pages/Blogs/BlogTres";

// Escaneos
import Docentes from "./scanes/Docentes";

function App() {
  return (
    <>
      {/* Encabezado */}
      <Header />

      <Routes>
        {/* Rutas Principales */}
        <Route path="/" element={<Home />} />
        <Route path="/nosotros" element={<About />} />
        <Route path="/transparencia" element={<Transparencia />} />
        <Route path="/novedades" element={<NovedadesBlog />} />
        <Route path="/servicios" element={<Services />} />
        <Route path="/galeria" element={<Gallery />} />
        <Route path="/contactanos" element={<Contact />} />
        <Route path="/adicionales" element={<Adicionales />} />
        <Route path="/admisiones" element={<Admisiones />} />
        <Route path="/oferta" element={<OfertaAcademica />} />
        <Route path="/galeria_videos" element={<GaleriaVideos />} />
        <Route path="/encuentranos" element={<Encuentranos />} />
        <Route path="/seguro" element={<Seguro />} />

        {/* Rutas de Blogs */}
        <Route path="/blog_uno" element={<BlogUno />} />
        <Route path="/blog_dos" element={<BlogDos />} />
        <Route path="/blog_tres" element={<BlogTres />} />

        {/* Escaneos */}
        <Route path="/docentes" element={<Docentes />} />

        {/* Ruta comodín */}
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>

      {/* Componentes globales */}
      <WhatsApp />
      <Footer />
    </>
  );
}

export default App;
