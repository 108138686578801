import React, { useEffect, useState, Suspense } from 'react';
import BannerAnimado from '../Components/BannerAnimado';
import CardNivelDetalle from 'Components/CardNivelDetalle';
import CardActividad from 'Components/CardActividad';
import salaCuna from '../assets/niveles/sala-cuna-servicios.webp';
import maternal1 from '../assets/niveles/maternal1-servicios.webp';
import maternal2 from '../assets/niveles/maternal2-servicios.webp';
import inicial1 from '../assets/niveles/inicial-1-servicios.webp';
import inicial2 from '../assets/niveles/inicial-2-servicios.webp';
import natacion from '../assets/niveles/natacion-1.webp';
import equitacion from '../assets/galeria/14.webp';
import futbol from '../assets/galeria/futbol-2.webp';
import terapiaLenguaje from '../assets/galeria/lenguaje-1.webp';
import ingles from '../assets/galeria/10.webp';
import musica from '../assets/galeria/niño-tocando-tambor.webp';
import tituloSalaCuna from '../assets/titulos/salacuna.webp';
import tituloMaternal1 from '../assets/titulos/maternal1.webp';
import tituloMaternal2 from '../assets/titulos/maternal2.webp';
import tituloInicial1 from '../assets/titulos/inicial1.webp';
import tituloInicial2 from '../assets/titulos/inicial2.webp';
import serviciosAdicionales from '../assets/titulos/servicios-adicionales.webp';
import textos from '../Data/textos.json';

const Services = () => {
  const [loading, setLoading] = useState(true);

  const scrollToSection = (inicio) => {
    window.location.hash = "#" + inicio;
  };

  useEffect(() => {
    scrollToSection("inicio");
    setTimeout(() => setLoading(false), 500); // Simulando carga de contenido
  }, []);

  return (
    <>
      <BannerAnimado
        tittle="Nuestros Servicios"
        size="display-4"
      />
      
      <Suspense fallback={<div className="loading-screen d-flex justify-content-center align-items-center">Cargando...</div>}>
        <div className={`fade-in-container ${loading ? "" : "loaded"}`}>
          <div className="container-fluid" id="niveles">
            <div className="container">
              <CardNivelDetalle
                imgTitulo={tituloSalaCuna}
                descripcion={textos.niveles.salaCuna.descripcionCompleta}
                edad={textos.niveles.salaCuna.edad}
                horario={textos.niveles.salaCuna.horario}
                direccionImg="derecha"
                img={salaCuna}
              />
              <CardNivelDetalle
                imgTitulo={tituloMaternal1}
                descripcion={textos.niveles.maternal.uno.descripcionCompleta}
                edad={textos.niveles.maternal.uno.edad}
                horario={textos.niveles.maternal.uno.horario}
                direccionImg="izquierda"
                img={maternal1}
              />
              <CardNivelDetalle
                imgTitulo={tituloMaternal2}
                descripcion={textos.niveles.maternal.dos.descripcionCompleta}
                edad={textos.niveles.maternal.dos.edad}
                horario={textos.niveles.maternal.dos.horario}
                direccionImg="derecha"
                img={maternal2}
              />
              <CardNivelDetalle
                imgTitulo={tituloInicial1}
                descripcion={textos.niveles.inicial.uno.descripcionCompleta}
                edad={textos.niveles.inicial.uno.edad}
                horario={textos.niveles.inicial.uno.horario}
                direccionImg="izquierda"
                img={inicial1}
              />
              <CardNivelDetalle
                imgTitulo={tituloInicial2}
                descripcion={textos.niveles.inicial.dos.descripcionCompleta}
                edad={textos.niveles.inicial.dos.edad}
                horario={textos.niveles.inicial.dos.horario}
                direccionImg="derecha"
                img={inicial2}
              />
            </div>
          </div>

          <div className="container-fluid pt-5">
            <div className="container pb-3">
              <div className="text-center pb-2">
                <img src={serviciosAdicionales} className="titulos" alt="Servicios adicionales" />
              </div>
              <div className="row">
                <CardActividad
                  titulo={textos.serviciosAdicionales.servicioUno.nombre}
                  descripcion={textos.serviciosAdicionales.servicioUno.descripcion}
                  imagen={natacion}
                />
                <CardActividad
                  titulo={textos.serviciosAdicionales.servicioDos.nombre}
                  descripcion={textos.serviciosAdicionales.servicioDos.descripcion}
                  imagen={terapiaLenguaje}
                />
                <CardActividad
                  titulo={textos.serviciosAdicionales.servicioTres.nombre}
                  descripcion={textos.serviciosAdicionales.servicioTres.descripcion}
                  imagen={equitacion}
                />
                <CardActividad
                  titulo={textos.serviciosAdicionales.servicioCuatro.nombre}
                  descripcion={textos.serviciosAdicionales.servicioCuatro.descripcion}
                  imagen={ingles}
                />
                <CardActividad
                  titulo={textos.serviciosAdicionales.servicioCinco.nombre}
                  descripcion={textos.serviciosAdicionales.servicioCinco.descripcion}
                  imagen={futbol}
                />
                <CardActividad
                  titulo={textos.serviciosAdicionales.servicioSeis.nombre}
                  descripcion={textos.serviciosAdicionales.servicioSeis.descripcion}
                  imagen={musica}
                />
              </div>
            </div>
          </div>
        </div>
      </Suspense>
    </>
  );
};

export default Services;
