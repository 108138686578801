import { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';
import tituloTestimonios from '../assets/titulos/papitos.webp';
import Aos from 'aos';

const Testimonios = () => {
    const [videos, setVideos] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const apiKey = 'AIzaSyDqD0EwUfgagn97cl3lErRrHz8Aa3A-guE'; // Reemplaza con tu clave API de YouTube
    const playlistId = 'PLzRJSDvB5xi2KUpMPH_T9Epxuxs5mIeet'; // Reemplaza con el ID de tu lista de reproducción

    useEffect(() => {
        Aos.init({ duration: 1500 });

        const fetchVideos = async () => {
            try {
                const response = await fetch(
                    `https://www.googleapis.com/youtube/v3/playlistItems?part=snippet&maxResults=10&playlistId=${playlistId}&key=${apiKey}`
                );
                const data = await response.json();
                if (data.items) {
                    setVideos(data.items);
                } else {
                    setError('No se encontraron videos');
                }
            } catch (error) {
                console.error('Error fetching videos:', error);
                setError('Hubo un error al cargar los videos');
            } finally {
                setLoading(false);
            }
        };

        fetchVideos();
    }, []);

    if (loading) {
        return (
            <div className="text-center py-5">
                <div className="spinner-border" role="status">
                    <span className="visually-hidden">Cargando...</span>
                </div>
            </div>
        );
    }

    if (error) {
        return (
            <div className="text-center py-5">
                <h4 className="text-danger">{error}</h4>
            </div>
        );
    }

    return (
        <>
            <div className="text-center testimonio-back">
                <img
                    src={tituloTestimonios}
                    className="padres mt-5"
                    alt="Testimonios"
                    data-aos="fade-up"
                />
            </div>
            <div className="container-fluid test-sobrepuesto py-5">
                <div className="container-md">
                    <Swiper
                        modules={[Navigation, Pagination, Autoplay]}
                        navigation
                        loop={true}
                        spaceBetween={30}
                        autoplay={{ delay: 5000 }}
                        breakpoints={{
                            320: {
                                slidesPerView: 1, // Pantallas pequeñas (móviles)
                                spaceBetween: 10,
                            },
                            768: {
                                slidesPerView: 2, // Pantallas medianas (tablets)
                                spaceBetween: 20,
                            },
                            1024: {
                                slidesPerView: 3, // Pantallas grandes (laptops y más)
                                spaceBetween: 30,
                            },
                        }}
                    >
                        {videos.map((video) => (
                            <SwiperSlide key={video.snippet.resourceId.videoId}>
                                <iframe
                                    src={`https://www.youtube.com/embed/${video.snippet.resourceId.videoId}?modestbranding=1&rel=0&autohide=1`}
                                    title={video.snippet.title}
                                    className="video-container borde color-borde-video img-fluid"
                                    frameBorder="0"
                                    allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen
                                    data-aos="fade-up"
                                    style={{ width: '100%', height: '400px' }} // Cambia el tamaño aquí
                                ></iframe>
                            </SwiperSlide>

                        ))}
                    </Swiper>
                </div>
            </div>
        </>
    );
};

export default Testimonios;
