import React, { useEffect } from 'react';
import { ImCheckmark } from "react-icons/im";
import logoJE from '../assets/logos/jardin-esperanza-logoi-cuadrado.webp';
import Aos from 'aos';

const PropuestaValor = () => {

    useEffect(() => {
        Aos.init({ duration: 1500 });
    }, []);

    return (
        <div className="container-fluid mb-4">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-5">
                        <img className="img-fluid rounded mb-5 mb-lg-0" src={logoJE} alt="Jardín Esperanza" data-aos="fade-up" />
                    </div>
                    <div className="col-lg-7">
                        <p className='texto-justificado' data-aos="fade-right">
                            En Jardín Esperanza, nos enorgullece ser mucho más que una entidad;
                            <strong className="text-green-oscuro"> somos un compromiso vivo con la excelencia y el servicio.</strong> Desde
                            nuestro inicio, hemos dedicado nuestros esfuerzos a nutrir y cultivar el desarrollo integral de cada
                            niño que forma parte de nuestra comunidad, creando un espacio donde la calidad, la innovación y
                            el compromiso son nuestros pilares fundamentales.
                        </p>
                        <ul className="list-inline m-0">
                            <li className="py-2" data-aos="fade-up">
                                <ImCheckmark size={12} style={{ marginRight: 10, color: '#4ab383' }} />
                                Tenemos compromiso con la Excelencia.
                            </li>
                            <li className="py-2" data-aos="fade-up">
                                <ImCheckmark size={12} style={{ marginRight: 10, color: '#4ab383' }} />
                                Velamos por un desarrollo Integral de los Niños.
                            </li>
                            <li className="py-2" data-aos="fade-up">
                                <ImCheckmark size={12} style={{ marginRight: 10, color: '#4ab383' }} />
                                Tenemos como pilares fundamentales la Innovación y la Calidad.
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PropuestaValor;
