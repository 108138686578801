import AliceCarousel from 'react-alice-carousel';
import tituloProfes from '../assets/titulos/profes.webp';
import karleymedina from '../assets/docentes/karley-medina.png';
import gabyorellana from '../assets/docentes/gaby-orellana.png';
import kathyanaChoco from '../assets/docentes/kathyana-choco.png';
import kristyCuzco from '../assets/docentes/kristy-cuzco.png';
import lucyVacacela from '../assets/docentes/lucy-vacacela.png';
import micaAvila from '../assets/docentes/mica-avila.png';
import textos from '../Data/textos.json';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import Aos from 'aos';

const DocentesHome = () => {
    useEffect(() => {
        Aos.init({ duration: 1500 });
    }, []);

    const itemsDocentes = [
        <div className="item d-flex justify-content-center" data-value="1">
            <img className="img-fluid-doc" src={karleymedina} alt="karleymedina" />
        </div>,
        <div className="item d-flex justify-content-center" data-value="2">
            <img className="img-fluid-doc" src={lucyVacacela} alt="lucyVacacela" />
        </div>,
        <div className="item d-flex justify-content-center" data-value="3">
            <img className="img-fluid-doc" src={gabyorellana} alt="gabyorellana" />
        </div>,
        <div className="item d-flex justify-content-center" data-value="4">
            <img className="img-fluid-doc" src={kathyanaChoco} alt="kathyanaChoco" />
        </div>,
        <div className="item d-flex justify-content-center" data-value="5">
            <img className="img-fluid-doc" src={kristyCuzco} alt="kristyCuzco" />
        </div>,
        <div className="item d-flex justify-content-center" data-value="6">
            <img className="img-fluid-doc" src={micaAvila} alt="micaAvila" />
        </div>
    ];

    const responsive = {
        0: { items: 1 },
        568: { items: 2 },
        1024: { items: 3 },
    };

    return (
        <div className="border-0 container-fluid pt-5">
            <div className="container">
                <div className="text-center pb-2" >
                    <img src={tituloProfes} className='titulos' alt="Nuestras profes" data-aos="fade-up"/>
                    {/*   <h1 className="mb-4 text-tomate-outline">Nuestras profesionales</h1> */}
                </div>
                <div className="row mx-2">
                    <AliceCarousel
                        autoPlay
                        autoPlayInterval={1000}
                        animationDuration={2000}
                        animationType="fadeleft"
                        infinite
                        touchTracking={false}
                        mouseTracking
                        disableDotsControls
                        disableButtonsControls
                        items={itemsDocentes}
                        responsive={responsive}
                    />
                </div>
                <div className="card- text-center">
                    <p className="card-text"  data-aos="fade-right">
                        {textos.docentes.decripcionGeneral}
                    </p>
                </div>
                <div className="d-flex align-items-center justify-content-center mt-4 mb-5">
                    <Link to="/docentes" className="btn btn-secondary">Conoce más sobre nuestras profesionales</Link>
                </div>
            </div>
        </div>
    )
}

export default DocentesHome
